/**
 * Module dependencies
 */
const React = require('react');
const Head = require('nordic/head');
const injectI18n = require('nordic/i18n/injectI18n');
const Script = require('nordic/script');
const Style = require('nordic/style');
const PropTypes = require('prop-types');
const Page = require('nordic/page');
const { useI18n } = require('nordic/i18n');
// eslint-disable-next-line import/no-unresolved
const Error404 = require('@andes/technical-error/presets/Error404');
const urlUtils = require('../../../utils/urlUtils');

/**
 * ErrorView Component
 */
function ErrorView(props) {
  const { i18n } = useI18n();
  const { notFound } = props;
  const namespace = 'ui-error';

  const preloadedState = {};
  const headerTitle = notFound ? i18n.gettext('Pagina no encontrada.') : i18n.gettext('Tendencias Error');
  const goBackText = i18n.gettext('Ir a la página principal de Tendencias');
  const urlWithoutCategory = urlUtils.getUrlWithoutCategory();

  return (
    <Page name="error" state={preloadedState}>
      <Head>
        <title>{headerTitle}</title>
        <Style href="error.css" />
      </Head>
      <Script src="vendor.js" />
      <div className={`${namespace}-container`}>
        <Error404 action={{ text: goBackText, href: urlWithoutCategory }} />
      </div>
    </Page>
  );
}


ErrorView.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  notFound: PropTypes.bool.isRequired,
};


/**
 * Inject i18n context as props into ErrorView.
 */
module.exports = injectI18n(ErrorView);
