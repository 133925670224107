/**
 * Styles
 */
require('../pages/error/styles.scss');

/**
 * Module dependencies
 */
const React = require('react');
const hydrate = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { AndesProvider } = require('@andes/context');

const ErrorView = require('../pages/error/views/errorView');

/**
 * Get server state
 */
const {
  translations,
  locale,
} = window.__PRELOADED_STATE__;

/**
 * i18n
 */
const i18n = new I18n({ translations });

/**
 * Mount ErrorView on client
 */
hydrate(
  <AndesProvider locale={locale}>
    <I18nProvider i18n={i18n}>
      <ErrorView {...window.__PRELOADED_STATE__} />
    </I18nProvider>
  </AndesProvider>,
  document.getElementById('root-app'),
);
